import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

import Customer1Image from "../images/customer_1.png";
import Customer2Image from "../images/customer_2.png";
import Customer3Image from "../images/customer_3.png";

import Container from "../components/container";
import {
  CARD_ELEVATION,
  PRIMARY_LIGHT_COLOR,
  SECTION_SX,
} from "../styles/shared";

const IMAGE_SIZE = 100;
const testimonials = [
  {
    profile: Customer1Image,
    heading: "Josh Douglas",
    subheading:
      "“StatusVista allows us to keep an eye on all of our services from a single page. It's been an integral tool for our DevOps team.”",
  },
  {
    profile: Customer2Image,
    heading: "Kelly Murphy",
    subheading:
      "“This is something I've needed for a long time. Having Slack notifications when things go down has been a real game changer.”",
  },
  {
    profile: Customer3Image,
    heading: "Nick Burns",
    subheading:
      "“StatusVista is my go-to when things seems off. I can see exactly when a system went down and when it comes back up. Keep up the good work.”",
  },
];

function Testimonial({ heading, profile, subheading }) {
  return (
    <Grid
      item
      xs={12}
      md={4}
      sx={{ textAlign: "center", mb: { xs: 8, md: 0 } }}
    >
      <Card
        sx={{
          height: "100%",
          borderBottom: "5px solid",
          borderColor: PRIMARY_LIGHT_COLOR,
          overflow: "visible",
        }}
        elevation={CARD_ELEVATION}
      >
        <CardContent sx={{ position: "relative", px: { xs: 4, lg: 6 } }}>
          <Box
            sx={{
              position: "absolute",
              top: -(IMAGE_SIZE / 2),
              left: "50%",
              transform: "translateX(-50%)",
              width: IMAGE_SIZE,
              height: IMAGE_SIZE,
              borderRadius: IMAGE_SIZE / 2,
              mb: 2,
              mx: "auto",
              overflow: "hidden",
            }}
          >
            <img alt="customer" src={profile} />
          </Box>
          <Typography variant="h6" sx={{ mb: 4, mt: 8 }}>
            {heading}
          </Typography>
          <Typography color="text.secondary" sx={{ pb: 4 }}>
            {subheading}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default function Testimonials() {
  return (
    <Box
      component="section"
      sx={{
        my: { md: 8 },
        ...SECTION_SX,
      }}
    >
      <Container sx={{ maxWidth: { md: "lg" } }}>
        <Typography
          variant="h4"
          fontWeight="fontWeightBold"
          sx={{ textAlign: "center", mb: 12 }}
        >
          What customers are saying
        </Typography>

        <Grid container spacing={2}>
          {testimonials.map((feature, idx) => (
            <Testimonial key={idx} {...feature} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
