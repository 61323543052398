import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import SwipeableViews from "react-swipeable-views";

import { ScrollProvider, useScrollContext } from "../contexts/scroll";

import Anchor from "../components/anchor";
import Contact from "../components/contact";
import Container from "../components/container";
import Cta from "../components/cta";
import Features from "../components/features";
import Footer from "../components/footer";
import Header from "../components/header";
import PageWrapper from "../components/page-wrapper";
import Stats from "../components/stats";
import SignupButton, { GoogleSignupButton } from "../components/signup-button";
import Testimonials from "../components/testimonials";

const pageDescription =
  "The ultimate status dashboard for developers. With the status of everything you depend on in one place, you can monitor services faster and more efficiently than ever before.";
const pageTitle = "Pricing plans";

function Hero() {
  return (
    <Box
      component="section"
      sx={{
        bgcolor: "primary.main",
        color: "common.white",
        pt: { xs: 2, md: 15 },
        pb: 20,
      }}
    >
      <Container>
        <Typography
          component="h1"
          variant="h2"
          fontWeight="fontWeightBold"
          sx={{ mb: 2, maxWidth: "md" }}
        >
          Pricing for all sizes
        </Typography>
        <Typography fontSize="larger" sx={{ maxWidth: "sm" }}>
          Get started <strong>free</strong> today, pick a plan later. All paid
          plans include a <u>7 day free trial</u>.
        </Typography>
        <Box sx={{ mt: 4, mb: 10 }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
          >
            <SignupButtons />
          </Stack>
          <Stack spacing={1} sx={{ display: { xs: "flex", sm: "none" } }}>
            <SignupButtons />
          </Stack>
        </Box>
        <Box sx={{ position: "relative", mb: 40 }}>
          <Plans />
        </Box>
      </Container>
    </Box>
  );
}

function Plans() {
  const [activeStep, setActiveStep] = useState(0);
  const { data, isLoading } = useQuery(["plans"], async () => {
    const result = await fetch(
      `${process.env.GATSBY_API_URL}/subscription/plans`
    );

    return result.json();
  });

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleTabChange = (_, step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ position: "absolute", left: 0, right: 0 }}>
      {isLoading ? (
        <Grid container spacing={2}>
          {Array.from({ length: 3 }).map((_, idx) => (
            <Grid key={idx} item xs={12} md={4}>
              <PricingCard isLoading={true} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box>
          <Box sx={{ display: { md: "none" } }}>
            <Tabs
              value={activeStep}
              variant="fullWidth"
              onChange={handleTabChange}
              sx={{
                backgroundColor: "common.white",
                mx: "2px",
              }}
            >
              {data.map((plan) => (
                <Tab key={plan.id} label={plan.title} />
              ))}
            </Tabs>
            <SwipeableViews
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              slideStyle={{ padding: "0 2px 2px 2px" }}
            >
              {data.map((plan) => (
                <PricingCard key={plan.id} data={plan} />
              ))}
            </SwipeableViews>
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Grid container spacing={2}>
              {data.map((plan) => (
                <Grid key={plan.id} item xs={12} md={4}>
                  <PricingCard data={plan} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

function Pricing() {
  const { headerHeight, pageContentRef } = useScrollContext();

  return (
    <>
      <Header variant="primary" />
      <Hero />
      <Anchor ref={pageContentRef} offset={headerHeight} />
      <Box sx={{ mt: { xs: 40, sm: 40, md: 50, lg: 45 } }}>
        <Anchor id="features" offset={headerHeight} />
        <Features />
        <Stats variant="light" sx={{ mb: 10 }} />
        <Anchor id="testimonials" offset={headerHeight} />
        <Testimonials />
        <Anchor id="contact" offset={headerHeight} />
        <Contact />
        <Cta />
      </Box>
      <Footer />
    </>
  );
}

function PricingCard({ data, isLoading = false }) {
  return (
    <Box
      component={Paper}
      sx={{ borderRadius: { xs: 0, md: 1 }, overflow: "hidden" }}
    >
      {isLoading ? (
        <Box>
          <Stack>
            <Skeleton animation="wave" variant="rectangular" height={48} />
            <Box sx={{ px: 2, py: 4, textAlign: "center" }}>
              <Skeleton animation="wave" variant="rectangular" height={40} />
            </Box>
            <Box sx={{ p: 2 }}>
              <Skeleton
                animation="wave"
                variant="circular"
                width={150}
                height={150}
                sx={{ mx: "auto", mb: 1 }}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                height={27}
                sx={{ mb: 8 }}
              />
              {Array.from({ length: 4 }).map((_, idx) => (
                <Box key={idx}>
                  <Skeleton
                    key={idx}
                    animation="wave"
                    variant="rectangular"
                    height={32}
                  />
                  {idx < 3 && <Divider sx={{ my: 2 }} />}
                </Box>
              ))}
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box>
          <Box>
            {data.banner ? (
              <Box
                sx={{
                  backgroundColor: "secondary.main",
                  px: 2,
                  py: 1,
                  textAlign: "center",
                }}
              >
                <Typography variant="h6" fontWeight="fontWeightBold">
                  {data.banner.toUpperCase()}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ height: { xs: 0, md: 48 } }} />
            )}
          </Box>
          <Box sx={{ px: 2, py: 4, textAlign: "center" }}>
            <Typography variant="h4" fontWeight="fontWeightBold" sx={{}}>
              {data.title} Plan
            </Typography>
          </Box>
          <Box sx={{ p: 2, textAlign: "center" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 150,
                height: 150,
                borderRadius: "50%",
                backgroundColor: data.price_id ? "error.light" : "info.light",
                color: "common.white",
                mx: "auto",
                mb: 1,
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: "fontWeightBold" }}>
                {data.price_id ? "$" : ""}
                {data.price}
              </Typography>
            </Box>
            <Typography
              sx={{ color: "text.secondary", fontSize: "large", mb: 8 }}
            >
              {data.priceDescription}
            </Typography>
            <Stack>
              {data.features.map((feature, idx) => {
                const { plan_flags, title } = feature;
                const enabled = !!plan_flags;
                const enabledCount = data.features.filter(
                  (f) => !!f.plan_flags
                ).length;

                if (!enabled) return false;

                return (
                  <Box key={idx}>
                    <Typography variant="h6">
                      {title}
                      {enabled && (
                        <>
                          {" "}
                          (
                          {plan_flags.amount === -1 ? (
                            <Typography
                              component="span"
                              variant="h6"
                              sx={{
                                fontWeight: "fontWeightBold",
                                color: "success.light",
                              }}
                            >
                              unlimited
                            </Typography>
                          ) : (
                            `${plan_flags.amount} ${feature.unit}`
                          )}
                          )
                        </>
                      )}
                    </Typography>
                    {idx < enabledCount - 1 && <Divider sx={{ my: 2 }} />}
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
}

function SignupButtons() {
  return (
    <>
      <Box>
        <SignupButton size="large" color="tertiary" />
      </Box>
      <Box>
        <GoogleSignupButton variant="outlined" color="tertiary" />
      </Box>
    </>
  );
}

export default function Page() {
  return (
    <PageWrapper pageDescription={pageDescription} pageTitle={pageTitle}>
      <ScrollProvider>
        <Pricing />
      </ScrollProvider>
    </PageWrapper>
  );
}
